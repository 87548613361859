import React, { Component } from "react";

// libraries
import { ApiList } from "../../config/apiList";

// components
import TopCard from "../common/topCard";
import API from "../../config/api";
import * as utils from "../common/utils";

class topSortCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topCardData: {
                curr: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
                prev: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
            },
            flag: false,
            _dateRange: ""
        };
    }

    componentDidMount() {
        this.setState({
            _dateRange: utils.subscribeDateRange(this.topThreeCard)
        })

        this.topThreeCard();
    }

    componentWillUnmount() {
        utils.unsubscribeDateRange(this.state._dateRange)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.topThreeCard();
        }
    }

    topThreeCard = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.summary}`;

        const data = await API.call(URL) // done
        // console.log("topcard data", data);

        this.setState({
            topCardData: data,
            flag: true,
        });
    };

    render() {
        return (
            <div>
                {this.state.flag ? (
                    <div className="row mt-4">
                        <div className="col-md-4">
                            <TopCard
                                name={"playout"}
                                sortBy={"playout"}
                                count={this.state.topCardData.curr.playout}
                                prevCount={this.state.topCardData.prev.playout}
                                color={"239,160,124"}
                            />
                        </div>
                        <div className="col-md-4">
                            <TopCard
                                name={"airtime"}
                                sortBy={"airtime"}
                                count={this.state.topCardData.curr.airtime}
                                prevCount={this.state.topCardData.prev.airtime}
                                color={"102,188,214"}
                            />
                        </div>
                        <div className="col-md-4">
                            <TopCard
                                name={"spend"}
                                sortBy={"spend"}
                                count={this.state.topCardData.curr.spend}
                                prevCount={this.state.topCardData.prev.spend}
                                color={"147,115,243"}
                            />
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default topSortCards;
