import React, { Component } from "react";

// libraries
import { ApiList } from "../../config/apiList";

import Lookup from "../../config/lookup";
import * as utils from "../common/utils";
import CountUp from "react-countup";

import API from "../../config/api";
import { ButtonGroup, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class ProgressList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            channelList: [],
            page_size: 10,
            page_number: 1,
            _dateRange: ""
        };
    }

    componentDidMount() {
        this.setState({
            _dateRange: utils.subscribeDateRange(this.fetch)
        })

        this.fetch();
    };

    componentWillUnmount() {
        utils.unsubscribeDateRange(this.state._dateRange);
    }

    componentDidUpdate(prevProps) {
        if (utils.arePropsChanged(prevProps, this.props)) {
            this.fetch();
        }
    }

    getPageLabels = () => {
        return this.state.channelList.length
            ? `Showing ${this.state.page_number * this.state.page_size -
            (this.state.page_size - 1)
            } - ${this.state.page_number * this.state.page_size >
                this.state.channelList.length
                ? this.state.channelList.length
                : this.state.page_number * this.state.page_size
            } items of ${this.state.channelList.length}`
            : "";
    };

    fetch = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.topList}/${this.props.for
            }/top?${utils.getParams(this.props)}`;

        const data = await API.call(URL) //done

        this.props.onItemsAvailable?.(data)

        this.setState({
            channelList: data,
        });
    };

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <header>
                        {Lookup.display[this.props.for] || this.props.for}
                        {this.state.channelList.length > 10 ? (
                            <ButtonGroup size="sm" className="float-right">
                                <Button
                                    disabled={this.state.page_number === 1}
                                    color="secondary"
                                    onClick={() => {
                                        let page_number =
                                            this.state.page_number;
                                        this.setState({
                                            page_number: --page_number,
                                        });
                                    }}
                                    outline
                                >
                                    <FontAwesomeIcon
                                        icon={"chevron-left"}
                                    />{" "}
                                </Button>
                                <Button
                                    disabled={
                                        this.state.page_number >=
                                        this.state.channelList.length /
                                        this.state.page_size
                                    }
                                    onClick={() => {
                                        let page_number =
                                            this.state.page_number;
                                        this.setState({
                                            page_number: ++page_number,
                                        });
                                    }}
                                    color="secondary"
                                    outline
                                >
                                    <FontAwesomeIcon
                                        icon={"chevron-right"}
                                    />{" "}
                                </Button>
                            </ButtonGroup>
                        ) : null}
                    </header>
                    {this.state.channelList.length > 10 ? (
                        <div>
                            <small>{this.getPageLabels()}</small>
                        </div>
                    ) : null}
                </div>
                {this.state.channelList.length ? (
                    <div className="card-body list">
                        {utils
                            .paginate(
                                this.state.channelList,
                                this.state.page_size,
                                this.state.page_number
                            )
                            .map((item, key) => {
                                return (
                                    <a
                                        className="row mb-4 link-s"
                                        href={
                                            this.props.for === "creatives"
                                                ? void 0
                                                : `/${this.props.for
                                                }/${encodeURIComponent(
                                                    item.name
                                                )}?${utils.getParams(this.props)}`
                                        }
                                        key={item.name}
                                    >
                                        <div className="col-1">
                                            <small className="badge badge-secondary">
                                                {key +
                                                    1 +
                                                    (this.state.page_number -
                                                        1) *
                                                    this.state.page_size}
                                            </small>
                                        </div>
                                        <div className="col-11">
                                            {" "}
                                            <span>{item.name}</span>
                                            <label className="badge badge-pill badge-primary float-right">
                                                <CountUp
                                                    end={item.count}
                                                    formattingFn={(value) => {
                                                        return utils.formatter(
                                                            this.props.sortBy,
                                                            value
                                                        );
                                                    }}
                                                />
                                            </label>
                                            <div
                                                className="progress"
                                                style={{ height: "3px" }}
                                            >
                                                <div
                                                    className="progress-bar bg-info"
                                                    role="progressbar"
                                                    aria-valuenow="0"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                    style={{
                                                        width: `${(item.count /
                                                            this.state
                                                                .channelList[0]
                                                                .count) *
                                                            100
                                                            }%`,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </a>
                                );
                            })}
                    </div>
                ) : (
                    <div className="card-body text-center text-muted p-5">
                        No Data Found
                    </div>
                )}
            </div>
        );
    }
}

export default ProgressList;
