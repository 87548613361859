import React, { Component } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// Library
import { ApiList } from "../../config/apiList";
// importing logo images
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import image from "../../images/email-sent.svg";
import Logo from "../../images/logo.png";

class ResetPassword extends Component {
    constructor() {
        super();
        this.state = {
            password: "",
            confirmpassword: "",
            validationFlag: false,
            modal: false,
            modalTitle: "",
            modalContent: "",
            modalStatus: "",
            reset: false,
        };
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleChangeConfirmPassword =
            this.handleChangeConfirmPassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
    }
    toggle(data) {
        this.setState({ ...this.state, ...data });
    }
    handleChangePassword(event) {
        this.setState({ password: event.target.value });
    }
    handleChangeConfirmPassword(event) {
        this.setState({ confirmpassword: event.target.value });
    }
    passwordValidation = () => {
        if (
            this.state.password === "" ||
            this.state.password === null ||
            this.state.password.length === 0 ||
            this.state.password.length < 7
        ) {
            this.toggle({
                modal: true,
                modalContent: "Invalid password. Please try again",
                modalStatus: "error",
                modalTitle: "Alert",
                validationFlag: false,
            });
            return false;
        }
        if (this.state.password !== this.state.confirmpassword) {
            this.toggle({
                modal: true,
                modalContent: "Passwords don't match. Please try again",
                modalStatus: "error",
                modalTitle: "Alert",
                validationFlag: false,
            });
            return false;
        } else {
            this.setState({ validationFlag: true });
        }
    };
    handleSubmit(event) {
        event.preventDefault();
        if (this.state.validationFlag) {
            this.setAuth();
        } else {
            this.passwordValidation();
        }
        return false;
    }

    setAuth = () => {
        //const [searchParams] = searchParams();
        const token = new URLSearchParams(window.location.search).get("token");

        fetch(
            `${ApiList.env}${ApiList.endpoint.updatepassword
            }?ts=${new Date().getTime()}`,
            {
                mode: "cors",
                method: "POST",
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
                body: JSON.stringify({
                    token,
                    password: this.state.confirmpassword,
                }),
            }
        )
            .then((data) => data.json())
            .then((data) => {
                console.log(data, "in data");
                if (data.error) {
                    this.toggle({
                        modal: true,
                        modalContent: data.reason,
                        modalStatus: "error",
                        modalTitle: "Alert",
                    });
                    return false;
                } else {
                    this.toggle({
                        modal: true,
                        modalContent: "Password changed successfully.",
                        modalStatus: "success",
                        modalTitle: "Success",
                        reset: true,
                    });
                    window.location.href = "/login";

                    return false;
                }
            })
            .catch((error) => {
                console.log(error);
                if (error.error) {
                    this.toggle({
                        modal: true,
                        modalContent: error.reason,
                        modalStatus: "error",
                        modalTitle: "Alert",
                    });
                }
                return;
            });
        return;
    };

    render() {
        return (
            <div className="auth-component">
                <Modal
                    isOpen={this.state.modal}
                    toggle={() => {
                        this.toggle({ modal: !this.state.modal });
                    }}
                    className="auth-modal"
                >
                    <ModalHeader
                        toggle={() => {
                            this.toggle({ modal: !this.state.modal });
                        }}
                    >
                        {this.state.modalTitle}
                    </ModalHeader>
                    <ModalBody>
                        {this.state.modalStatus === "success" ? (
                            <FontAwesomeIcon
                                color="#60FF79"
                                icon={"check-circle"}
                                size="5x"
                            />
                        ) : (
                            <FontAwesomeIcon
                                color="#ffd23d"
                                icon={"exclamation"}
                                size="5x"
                            />
                        )}
                        <p className="text-center mt-5">
                            {this.state.modalContent}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            onClick={() => {
                                this.toggle({ modal: !this.state.modal });
                            }}
                        >
                            Okay
                        </Button>
                    </ModalFooter>
                </Modal>
                <div className="top-section">
                    <div className="top-section-lhs">
                        <h1>
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>
                        <h2>Set New Password</h2>
                        <h3>
                            Enter your new password, <br />
                            must be different to previously used passwords.
                        </h3>
                    </div>
                    <div className="top-section-rhs">
                        <h1 className="mobile-view">
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>

                        <img
                            src={image}
                            alt="SyncMedia Login"
                            title="SyncMedia Login"
                        />
                    </div>
                </div>
                <div className="bottom-section">
                    <div className="form-container">
                        {this.state.reset ? (
                            <p className="text-success">
                                Password changed successfully
                            </p>
                        ) : (
                            <form onSubmit={this.handleSubmit} className="form">
                                <input
                                    type="password"
                                    placeholder="Enter new password"
                                    className="password-input"
                                    aria-label="password input"
                                    aria-describedby=""
                                    value={this.state.password}
                                    //onBlur={this.passwordValidation}
                                    required
                                    minLength={8}
                                    onChange={this.handleChangePassword}
                                />
                                <input
                                    type="password"
                                    placeholder="Verify new password"
                                    className="password-input"
                                    aria-label="verify password input"
                                    aria-describedby=""
                                    required
                                    minLength={8}
                                    onBlur={this.passwordValidation}
                                    value={this.state.confirmpassword}
                                    onChange={this.handleChangeConfirmPassword}
                                />
                                <p className="text-white">
                                    Must be atleast 8 characters
                                </p>
                                <button className="btn" type="submit">
                                    Reset Password
                                </button>
                            </form>
                        )}
                        <div className="text-center">
                            <a href="/login" className="text-white">
                                Back to login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ResetPassword;
