import React, { Component } from "react";

// libraries
import { Line } from "react-chartjs-2";
import moment from "moment";
import { ApiList } from "../../config/apiList";
import * as utils from "../common/utils";

import API from "../../config/api";

class TopCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name,
            count: props.count,
            prevCount: props.prevCount,

            color: props.color,
            labelsData: [],
            dataSet: [],
            _dateRange: ""
        };
    }

    componentDidMount = () => {
        this.setState({
            _dateRange: utils.subscribeDateRange(this.getStatistics)
        })
        this.getStatistics();
    };

    componentWillUnmount() {
        utils.unsubscribeDateRange(this.state._dateRange)
    }

    getStatistics = async () => {
        const URL = `${ApiList.env}${ApiList.endpoint.statistics}daily?sortBy=${this.props.sortBy}`;

        const data = await API.call(URL) // done

        let xData = [];
        let yData = [];

        data.forEach((item, key) => {
            xData.push(item.count ? item.count : 0);
            yData.push(item.date ? moment(item.date).format("DD") : 0);
        });

        this.setState({
            dataSet: xData,
            labelsData: yData,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.getStatistics();
        }
    }

    render() {
        const diff = this.props.count - this.props.prevCount;
        let per = ((diff / Math.max(this.props.prevCount, 1)) * 100).toFixed(0);
        let result = per <= 0 ? "fall" : "rise";

        const topCardLineOption = {
            responsive: false,
            maintainAspectRatio: false,
            animation: {
                easing: "easeInOutQuad",
                duration: 520,
            },
            tooltips: {
                mode: "index",
                intersect: false,
            },

            scales: {
                xAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: { padding: 56, display: false },
                    },
                ],
                yAxes: [
                    {
                        gridLines: {
                            drawBorder: false,
                            color: "#464967",
                            display: false,
                            lineWidth: 1,
                            zeroLineColor: "#464967",
                        },
                        ticks: {
                            beginAtZero: true,
                            padding: 3,
                            display: false,
                        },
                    },
                ],
            },
            elements: {
                line: {
                    tension: 0.4,
                },
                point: { radius: 0 },
            },
            legend: { display: false },
        };

        const topCardDataLine = (canvas) => {
            const ctx = canvas.getContext("2d");
            const gradient = ctx.createLinearGradient(0, 0, 0, 45);

            gradient.addColorStop(0, "rgba(" + this.state.color + ", 0.5)");
            gradient.addColorStop(0.5, "rgba(" + this.state.color + ", 0.1)");
            gradient.addColorStop(0.7, "rgba(" + this.state.color + ", 0.005)");
            gradient.addColorStop(1, "rgba(" + this.state.color + ", 0)");

            return {
                labels: this.state.labelsData,
                datasets: [
                    {
                        label: this.props.sortBy.toUpperCase(),
                        backgroundColor: gradient,
                        borderWidth: 2,
                        borderColor: "rgb(" + this.state.color + ")",
                        data: this.state.dataSet,
                    },
                ],
            };
        };

        let display = utils.formatter(this.props.name, this.props.count);

        return (
            <div className="card">
                <div className="card-body row">
                    <div className="col-8">
                        <h6 className="text-muted">{this.state.name}</h6>
                        <div className="count">
                            <div
                                className="numbers text-truncate"
                                title={display}
                            >
                                {display}

                                <div className="rise-fall-per d-inline-block ml-3">
                                    <div className={result}>
                                        {per > 200 ? "" : Math.abs(per) + "%"}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-4">
                        <Line
                            data={topCardDataLine}
                            options={topCardLineOption}
                            width={100}
                            height={100}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default TopCard;
