import React, { useEffect, useState } from "react";

import { Modal, ModalBody } from "reactstrap";
// library

import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { DateRangePicker as ReactDateRangePicker } from 'react-date-range';
import * as utils from "../common/utils";

const CustomDateRangePicker = (props) => {

  const [dateLimit, _setDateLimit] = useState(() => {
    return utils.dateLimit()
  })

  const [selectedRange, setSelectedRange] = useState(() => {
    return utils.dateRange()
  })

  const [calendarRange, setCalenderRange] = useState(() => {
    return utils.dateRange()
  })

  const [showModal, setShowModal] = useState(false)

  const [errorMessage, setErrorMessage] = useState('')

  return (
    <div>
      <Modal
        isOpen={showModal}
        toggle={() => setShowModal(!showModal)}
        size="lg"
        style={{ width: '890px', maxWidth: '890px' }}
      >
        <div className="card-header">
          <h6 className="float-left">Select Date Range</h6>
          <button
            type="button"
            className="close float-right"
            data-dismiss="modal"
            onClick={() => setShowModal(false)}
          >
            <i className="lni lni-close"></i>
          </button>
        </div>

        <ModalBody className="card p-0 rounded-0">
          <div className="row">
            <div className="col">
              <ReactDateRangePicker
                ranges={[
                  {
                    startDate: calendarRange.since.toDate(),
                    endDate: calendarRange.till.toDate(),
                    key: 'selection'
                  }
                ]}
                inputRanges={[]}
                minDate={dateLimit.minimum.toDate()}
                maxDate={dateLimit.maximum.toDate()}
                months={2}
                direction={"horizontal"}
                onChange={
                  (rangesByKey) => {
                    let range = {
                      since: moment(rangesByKey['selection'].startDate),
                      till: moment(rangesByKey['selection'].endDate)
                    }

                    if (range.since.isBefore(dateLimit.minimum) || range.till.isAfter(dateLimit.maximum)) {
                      setErrorMessage('Premium account is Required. Please Contact Support')
                      return
                    }
                    setErrorMessage("")
                    setCalenderRange(range)
                  }}
              />
              {errorMessage && (
                <p className="text-center text-danger">
                  {errorMessage}
                </p>
              )}
            </div>
          </div>
        </ModalBody>
        <div className="modal-footer">
          <button className="btn btn-link" onClick={() => {
            setCalenderRange(selectedRange)
          }}>
            Reset
          </button>
          <button className="btn btn-primary" onClick={
            () => {
              setShowModal(false)
              setSelectedRange(calendarRange)

              utils.updateDateRangeTo(calendarRange.since, calendarRange.till)

              props.onDateChange(calendarRange)
            }
          }>
            Submit
          </button>
        </div>
      </Modal >
      <div className="row">
        <div className="col-6">

        </div>
        <div className="col-6 text-right">
          <button
            type="button"
            className="btn btn-sm text-truncate btn-outline-secondary rounded-pill"
            onClick={() => {
              setCalenderRange(selectedRange)
              setShowModal(true)
            }}
          >
            <FontAwesomeIcon icon={"calendar-alt"} />{" "}
            {selectedRange.since.format("DD-MMM-YYYY")} to {selectedRange.till.format("DD-MMM-YYYY")}
          </button>
        </div>
      </div>
    </div >
  );
}

export default CustomDateRangePicker