import React, { Component, useEffect, useMemo, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

type OnClickCallback = (selected: string) => void

type SortProps = {
  className: string
  label: string | undefined
  selected: string | undefined
  options: Array<string> | undefined
  onClickCallback: OnClickCallback
}

const defaultOptions = ["playout", "airtime", "spend"]

const SortByFilter = (props: SortProps) => {

  const options = useMemo(() => {
    return props.options || defaultOptions
  }, [props.options])

  const [selected, setSelected] = useState(props.selected || options[0])

  useEffect(() => {
    props.onClickCallback(selected)
  }, [selected])

  return (
    <React.Fragment>
      {props.label ? <label>{props.label || "Sort By"}</label> : null}
      <UncontrolledDropdown className={props.className}>
        <DropdownToggle caret className="text-capitalize pr-5">
          {selected || "Select"}
        </DropdownToggle>
        <DropdownMenu right>
          {options.map((i, j) => {
            return (
              <DropdownItem
                className="text-capitalize"
                key={i}
                onClick={() => {
                  setSelected(i)
                }}
              >
                {i}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
    </React.Fragment>
  );
}

export default SortByFilter;
