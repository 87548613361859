import React, { Component } from "react";
import Nav from "../navigation";
import Breadcrumbs from "./breadcrumb";
class Layout extends Component {
  render() {
    return (
      <div>
        <Nav active={this.props.location.pathname} />
        <main id="main-content">
          <div className="container-fluid">
            <Breadcrumbs />
            {this.props.children}
          </div>
        </main>
      </div>
    );
  }
}
export default Layout;
