/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
// Library
import { ApiList } from "../../config/apiList";
// importing logo images
import image from "../../images/forgot-password.svg";
import image2 from "../../images/reset-password.svg";
import Logo from "../../images/logo.png";
import toast from 'react-hot-toast';

class ForgotPassword extends Component {
    constructor() {
        super();
        this.state = {
            username: "",
            password: "",
            validationFlag: true,
            emailSent: false,
        };
        this.handleChangeUsername = this.handleChangeUsername.bind(this);
        this.handleChangePassword = this.handleChangePassword.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.body.classList.add("dark");
    }

    handleChangeUsername(event) {
        this.setState({ username: event.target.value });
    }
    handleChangePassword(event) {
        this.setState({ password: event.target.value });
    }
    usernameValidation = () => {
        if (
            this.state.username === "" ||
            this.state.username === null ||
            this.state.username.length === 0
        ) {
            toast.error("Invalid Input", {
                position: "top-right"
            })

            this.setstate = {
                validationFlag: false,
            };
        }
    };
    passwordValidation = () => {
        if (
            this.state.password === "" ||
            this.state.password === null ||
            this.state.password.length === 0
        ) {
            toast.error("Invalid Input", {
                position: "top-right"
            })

            this.setstate = {
                validationFlag: false,
            };
        }
    };

    handleSubmit(event) {
        event.preventDefault();
        if (!this.state.username) {

            toast.error("Invalid email address", {
                position: "top-right"
            })
            return false;
        }
        if (this.state.validationFlag) {
            this.setAuth(this.state.username);
        } else {
            toast.error("Invalid input", {
                position: "top-right"
            })
        }
    }

    setAuth = (user) => {
        fetch(
            `${ApiList.env}${ApiList.endpoint.resetpassword}${this.state.username}`,
            {
                mode: "cors",
                method: "GET",
                cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
                credentials: "same-origin", // include, *same-origin, omit
                headers: {
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
                redirect: "follow", // manual, *follow, error
                referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            }
        ).then((data) => {

            if (data.status !== 200) {
                toast.error("Something went wrong. Please try again later", {
                    position: "top-right"
                });
                return;
            }

            toast.success('Email sent successfully. Please check your inbox', {
                position: 'top-right'
            });

            this.setState({
                emailSent: true,
            });
        });
        return;
    };

    render() {
        return this.state.emailSent ? (
            <div className="auth-component">
                <div className="top-section">
                    <div className="top-section-lhs">
                        <h1>
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>
                        <h2>Check Your Email</h2>
                        <h3>
                            We have sent a link to reset
                            <br /> password to your Email.
                        </h3>
                    </div>
                    <div className="top-section-rhs">
                        <h1 className="mobile-view">
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>

                        <img
                            src={image}
                            alt="SyncMedia Password Reset"
                            title="SyncMedia Password Reset"
                        />
                    </div>
                </div>
                <div className="bottom-section">
                    <div className="form-container">
                        <a
                            className="btn"
                            href="mailto: "
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open Email App
                        </a>
                        <div className="d-flex justify-content-between">
                            <a href="/login" className="text-white">
                                Back to login
                            </a>
                            <span>
                                Didn't receive the email?&nbsp;
                                <a
                                    href="#"
                                    onClick={() => {
                                        this.setAuth();
                                        this.setState({
                                            emailSent: false,
                                        });
                                        toast.success('Email resent successfully', {
                                            position: "top-right"
                                        })
                                    }}
                                    className="text-white"
                                >
                                    Resent
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            <div className="auth-component">
                <div className="top-section">
                    <div className="top-section-lhs">
                        <h1>
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>
                        <h2>Forget Password?</h2>
                        <h3>
                            No worries, <br />
                            We’ll send you the reset instructions.
                        </h3>
                    </div>
                    <div className="top-section-rhs">
                        <h1 className="mobile-view">
                            <a href="/">
                                <img
                                    src={Logo}
                                    alt="SyncMedia"
                                    title="SyncMedia"
                                />
                            </a>
                        </h1>

                        <img
                            src={image2}
                            alt="SyncMedia Login"
                            title="SyncMedia Login"
                        />
                    </div>
                </div>
                <div className="bottom-section">
                    <div className="form-container">
                        <form onSubmit={this.handleSubmit} className="form">
                            <input
                                type="text"
                                placeholder="Enter your email address"
                                className="username-input"
                                aria-label="email address input"
                                aria-describedby=""
                                onBlur={this.usernameValidation}
                                value={this.state.username}
                                onChange={this.handleChangeUsername}
                            />
                            <button className="btn" type="submit">
                                Reset Password
                            </button>
                        </form>
                        <div className="text-center">
                            <a href="/login" className="">
                                Back to login
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ForgotPassword;
