import React, { Component } from "react";

// Library
import { ApiList } from "../../config/apiList";
// importing logo images
import login from "../../images/login.svg";
import Logo from "../../images/logo.png";
import toast from 'react-hot-toast'
import { fetchDateLimit } from "../common/utils";

class SignIN extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      validationFlag: true,
      ROLE_ADMIN: "admin",
      ROLE_DASHBOARD: "dashboard",
      ROLE_MOST_POPULAR: "dashboard_cards",
      ROLE_ADLYTICS: "dashboard_adlytics",
      ROLE_COMPARE: "dashboard_compare",
    };
    this.handleChangeUsername = this.handleChangeUsername.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    document.body.classList.add("dark");
  }

  handleChangeUsername(event) {
    this.setState({ username: event.target.value });
  }
  handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  showError = (text) => {
    toast.error(text, {
      position: "top-right"
    });
  };

  handleSubmit(event) {
    event.preventDefault();

    if (
      this.state.username === "" ||
      this.state.username === null ||
      this.state.username.length === 0
    ) {
      this.showError("Invalid Username");
      return;
    }

    if (
      this.state.password === "" ||
      this.state.password === null ||
      this.state.password.length === 0
    ) {
      this.showError("Invalid Password");
      return;
    }

    this.setAuth(this.state.username, this.state.password);
  }

  flushAPICache() {
    let keys = Object.keys(localStorage);
    if (keys) {
      keys.forEach((item) => {
        if (
          item.includes("https://prod-api.syncmedia.io") ||
          item.includes("https://staging-api.syncmedia.io")
        ) {
          localStorage.removeItem(item);
        }
      });
    }
  }

  storeProfile(data) {
    if (data.roles.length > 0 && !Array.isArray(data.roles)) {
      data.roles = data.roles.split(",");
    }
    localStorage.setItem("account_details", JSON.stringify(data));
  }

  openLogin() {
    window.location.href = "/login";
  }

  routeHome(roles) {
    if (
      roles.includes(this.state.ROLE_ADMIN) ||
      roles.includes(this.state.ROLE_DASHBOARD)
    ) {
      window.location.href = '/home'
    } else if (roles.includes(this.state.ROLE_ADLYTICS)) {
      if (window.location.hostname.includes("staging")) {
        window.location.href = "https://staging-adlytics.syncmedia.io/";
      } else {
        window.location.href = "https://adlytics.syncmedia.io/";
      }
    } else if (roles.includes(this.state.ROLE_COMPARE)) {
      window.location.href = "/compare";
    } else {
      this.openLogin();
    }
  }

  setAuth = async (user, pass) => {
    let dataString = {
      user: user,
      pass: pass,
      service: "dashboard",
    };

    const response = await fetch(ApiList.env + ApiList.endpoint.login, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
      },
      credentials: 'include',
      body: JSON.stringify(dataString),
    })

    if (response.status > 299) {
      this.showError("Invalid Credentials");
      return
    }

    const data = await response.json()

    this.flushAPICache();

    await fetchDateLimit()

    this.storeProfile(data);
    this.routeHome(data.roles);
  };

  render() {
    return (
      <div className="auth-component">
        <div className="top-section">
          <div className="top-section-lhs">
            <h1>
              <a href="/">
                <img src={Logo} alt="SyncMedia" title="SyncMedia" />
              </a>
            </h1>
            <h2>Welcome!</h2>
            <h3>
              Unlock Insights.
              <br />
              Empower Decision-Making.
            </h3>
            <h6>
              Please login with your credentials to start viewing Ad Analytics.
            </h6>
          </div>
          <div className="top-section-rhs">
            <h1 className="mobile-view">
              <a href="/">
                <img src={Logo} alt="SyncMedia" title="SyncMedia" />
              </a>
            </h1>

            <img src={login} alt="SyncMedia Login" title="SyncMedia Login" />
          </div>
        </div>
        <div className="bottom-section">
          <div>
            <form onSubmit={this.handleSubmit} className="form">
              <input
                type="text"
                placeholder="Enter your email address"
                className="username-input"
                aria-label="email address input"
                aria-describedby=""
                value={this.state.username}
                onChange={this.handleChangeUsername}
              />
              <input
                type="password"
                placeholder="Enter your password"
                className="password-input"
                aria-label="password input"
                aria-describedby=""
                value={this.state.password}
                onChange={this.handleChangePassword}
              />
              <button className="btn" type="submit">
                Log in
              </button>
            </form>
            <div>
              <a href="/forgot_password">Forgot Password</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default SignIN;
