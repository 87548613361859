import React, { Component } from "react";
import { Breadcrumb, BreadcrumbItem, Badge } from "reactstrap";

class Breadcrumbs extends Component {
  constructor(props) {
    super(props);
    this.ignoreList = ["since", "till", "sortBy"];
    this.lookup = this.whiteList = [
      "channels",
      "brands",
      "categories",
      "products",
      "search",
      "compare",
    ];

    const paths = window.location.pathname.split("/").filter(x => !!x)
    if (!paths.includes("home")) {
      paths.unshift("home")
    }

    this.state = {
      url: window.location.href,
      path: paths,
      params: Object.keys(window.location.search).length
        ? JSON.parse(
          '{"' +
          window.location.search
            .substring(1)
            .replace(/&/g, '","')
            .replace(/=/g, '":"') +
          '"}',
          function (key, value) {
            return key === "" ? value : decodeURIComponent(value);
          }
        )
        : {},
    };
  }
  render() {
    return (
      <div className="mt-2 row">
        <div className="col">
          <Breadcrumb>
            {this.state.path
              .map((path, index) => {
                return (
                  <BreadcrumbItem
                    key={path}
                    active={this.state.path.length - 1 === index ? true : false}
                  >
                    <a
                      href={
                        index === 0 ? "/home" :
                          `/${this.state.path.slice(1, index + 1).join("/")}`
                      }
                    >
                      {decodeURIComponent(
                        path.replace("brand", "advertiser") || "Home"
                      )}
                    </a>
                  </BreadcrumbItem>
                );
              })}
          </Breadcrumb>
        </div>
        <div className="col text-right mt-2">
          {Object.keys(this.state.params)
            .filter((i) => !this.ignoreList.includes(i))
            .map((j) => {
              return (
                <Badge
                  key={j}
                  color="info"
                  className="mx-1 mb-2 p-2 rounded-pill"
                >
                  {j.replaceAll("brands", "advertisers") +
                    ": " +
                    decodeURIComponent(
                      this.state.params[j].replaceAll("+", " ")
                    )}
                </Badge>
              );
            })}
        </div>
      </div>
    );
  }
}
export default Breadcrumbs;
