import React, { Component } from "react";

class NoMatch extends Component {
  render() {
    return (
      <div>
        <h1 className="mt-5 text-center">Page Not Found</h1>
      </div>
    );
  }
}
export default NoMatch;
