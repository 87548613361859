import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import LineChart from "../charts/line_chart";
import { ResponsiveHeatMap } from "@nivo/heatmap";

// libraries
import moment from "moment";
import qs from "query-string";
import { ApiList } from "../../config/apiList";
import Lookup from "../../config/lookup";
import * as utils from "../common/utils";

import API from "../../config/api";

const emptyLineChartData = {
    loaded: false,
    key: [],
    sortBy: '',
    labels: [],
    data: [],
    color: "#000"
}

const chartKeys24 = Array(23).fill().map((element, index) => `${index + 0}`)
const chartKeysAM = Array(12).fill().map((element, index) => `${index + 0}`)
const chartKeysPM = Array(12).fill().map((element, index) => `${index + 12}`)

const chartTabWeekday = 'weekday'
const chartTabDaily = 'daily'
const chartTabHourly = 'hourly'

const heatMapTheme = {
    axis: {
        fontSize: "14px",
        tickColor: "#eee",
        ticks: {
            line: {
                stroke: "#555555",
            },
            text: {
                fill: "#aaaaaa",
            },
        },
        legend: {
            text: {
                fill: "#aaaaaa",
            },
        },
    },
    grid: {
        line: {
            stroke: "#555555",
        },
    },
    tooltip: {
        container: {
            background: "#000000",
            color: "#FFF",
        },
    },
};

const daynames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

class OverviewTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sortBy: props.sortBy,
            forName: props.forName,
            listData: [],
            heatChartData: [],
            chartKeys: chartKeys24,
            lineChartData: emptyLineChartData,
            chartTab: chartTabWeekday,
            weekdayChartData: [],
            _dateRange: ""
        };
    }

    componentDidMount = () => {
        this.setState({
            _dateRange: utils.subscribeDateRange(this.fetchTopData)
        })

        this.fetchTopData();
    };

    componentWillUnmount() {
        utils.unsubscribeDateRange(this.state._dateRange)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            console.log("componentDidUpdate")
            this.fetchTopData();
        }
    }

    fetchTopData = async () => {
        const URL =
            ApiList.env +
            ApiList.endpoint.topList +
            "/" +
            this.props.forName +
            "/top?sortBy=" +
            this.props.sortBy +
            "&limit=10";

        const data = await API.call(URL) // done

        console.log("fetchTopData done.. calling")

        this.setState(
            {
                listData: data,
                lineChartData: emptyLineChartData,
                heatChartData: [],
                weekdayChartData: [],
            }, () => {
                this.onChartSelected(this.state.chartTab)
            });
    };

    getStatisticsDaily = async (listData) => {
        this.setState({ lineChartData: emptyLineChartData });

        const URL =
            ApiList.env +
            ApiList.endpoint.statistics +
            "daily?sortBy=" +
            this.props.sortBy +
            "&" +
            this.props.forName +
            "=" +
            encodeURIComponent(listData.map(x => x.name).join(","));

        const data = await API.call(URL)

        let xData = [];
        let yData = [];

        data.forEach((item, key) => {
            xData.push(item.count ? item.count : 0);
            yData.push(
                item.date ? moment(item.date).format("DD-MM") : 0
            );
        });

        this.setState({
            lineChartData: {
                key: yData,
                sortBy: this.props.sortBy,
                labels: yData,
                data: xData,
                color: "#000",
            }
        });

        console.log("update linechart data")
    };

    getStatisticsWeekday = async (listData) => {
        const URL =
            ApiList.env +
            ApiList.endpoint.statistics +
            "dayhour?sortBy=" +
            this.props.sortBy +
            "&" +
            this.props.forName +
            "=" +
            encodeURIComponent(listData.map(x => x.name).join(","));

        const data = await API.call(URL)

        let weekDays = {}

        daynames.forEach(day => {
            weekDays[day] = Array(24).fill(0)
        })

        data.forEach((item) => {
            const weekday = item.day.split("-")[0];
            const hour = parseInt(item.day.split("-")[1], 10)
            weekDays[weekday][hour] = item.count;
        });

        /*
        weekDays -> {
            mon -> [ hourly indexed count ], 
            tue -> [ 1, 0, 1000, ...] 24 items each row
            ...
        }
        */

        let result = [];

        daynames.forEach((key) => {
            const array = weekDays[key];
            let obj = {};
            obj[key] = array.map((N, I) => {
                return { hour: I, count: N };
            });
            result.push(obj);
        });

        /**
         * result -> [ {mon: [ {hour: , count: } ]}  ]
        */
        let convertToHeatData = [];
        result
            .forEach((itemValue, i) => {
                let date = Object.keys(itemValue);
                let tempHold = {};
                itemValue[date].forEach((itemhour) => {
                    tempHold = {
                        ...tempHold,
                        [itemhour.hour]: itemhour.count,
                    };
                });
                tempHold = { ...tempHold, date: date.toString() };
                convertToHeatData.push(tempHold);
            });
        this.setState({
            weekdayChartData: convertToHeatData,
        });

        console.log("update weekday data")
    }

    getStatisticsHourly = async (listData) => {
        const URL =
            ApiList.env +
            ApiList.endpoint.statistics +
            "dailyhourly?sortBy=" +
            this.props.sortBy +
            "&" +
            this.props.forName +
            "=" +
            encodeURIComponent(listData.map(x => x.name).join(','));

        const data = await API.call(URL)

        let monthDays = {};

        data.forEach((item) => {
            const m = moment(item.dateHour);
            let monthDay = m.format("MMM DD");
            let hour = m.hour();

            let monthDayArr = monthDays[monthDay];

            if (!monthDayArr) {
                monthDayArr = Array(24).fill(0);
            }
            monthDayArr[hour] = item.count;
            // .push({ hour: hour, count: item.count });
            monthDays[monthDay] = monthDayArr;
        });

        const keys = Object.keys(monthDays);
        let result = [];
        // keys.map((key) => { Replaced with foreach
        keys.forEach((key) => {
            const array = monthDays[key];
            let obj = {};
            obj[key] = array.map(function (N, I) {
                return { hour: I, count: N };
            });
            result.push(obj);
        });

        let convertToHeatData = [];
        let nth = parseInt(result.length / 10) || 1;
        result
            .filter((e, i) => i % nth === nth - 1)
            .forEach((itemValue, i) => {
                let date = Object.keys(itemValue);
                let tempHold = {};
                itemValue[date].forEach((itemhour) => {
                    tempHold = {
                        ...tempHold,
                        [itemhour.hour]: itemhour.count,
                    };
                });
                tempHold = { ...tempHold, date: date.toString() };
                convertToHeatData.push(tempHold);
            });
        this.setState({
            heatChartData: convertToHeatData,
        });

        console.log("update hourly data")
    };

    amPmHandler = (event) => {
        let selected = event.target.value;
        if (selected === "am/pm") {
            this.setState({
                chartKeys: chartKeys24,
            });
        } else if (selected === "am") {
            this.setState({
                chartKeys: chartKeysAM,
            });
        } else if (selected === "pm") {
            this.setState({
                chartKeys: chartKeysPM,
            });
        }
    };

    renderLineChart(lineChartData = emptyLineChartData) {
        return (
            <>
                <LineChart
                    key={lineChartData.key}
                    sortBy={lineChartData.sortBy}
                    labels={lineChartData.labels}
                    data={lineChartData.data}
                    color={lineChartData.color}
                />
            </>)
    }

    renderHeatMap(
        data, keys, indexBy
    ) {
        return (
            <>
                <ResponsiveHeatMap
                    data={data}
                    keys={keys}
                    indexBy={indexBy}
                    margin={{
                        top: 0,
                        right: 0,
                        bottom: 50,
                        left: 50,
                    }}
                    colors="purples"
                    borderColor={{
                        from: "color",
                        modifiers: [
                            ["darker", 0.6],
                            ["opacity", 0.5],
                        ],
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: "bottom",
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legendPosition: "middle",
                        legendOffset: 36,
                    }}
                    axisLeft={{
                        orient: "left",
                        tickSize: 5,
                        tickPadding: 0,
                        tickRotation: 0,
                        legend: "",
                        legendPosition: "middle",
                        legendOffset: -40,
                    }}
                    cellOpacity={1}
                    enableLabels={false}
                    labelTextColor={{
                        from: "color",
                        modifiers: [["darker", 1.8]],
                    }}
                    defs={[
                        {
                            id: "lines",
                            type: "patternLines",
                            background: "inherit",
                            color: "rgba(0, 0, 0, 0.1)",
                            rotation: -45,
                            lineWidth: 4,
                            spacing: 7,
                        },
                    ]}
                    fill={[{ id: "lines" }]}
                    animate={true}
                    motionStiffness={80}
                    theme={heatMapTheme}
                    motionDamping={9}
                    hoverTarget="cell"
                    cellHoverOthersOpacity={0.25}
                />
            </>)
    }

    onChartSelected(chartTab) {
        if (this.state.listData.length === 0) {
            return
        }

        console.log(`onchart selected: ${chartTab}`)

        this.setState({ chartTab: chartTab });

        switch (chartTab) {
            case chartTabWeekday:
                this.getStatisticsWeekday(this.state.listData);
                break
            case chartTabDaily:
                this.getStatisticsDaily(this.state.listData);
                break;
            case chartTabHourly:
                this.getStatisticsHourly(this.state.listData);
                break;
        }
    }

    render() {
        const { forName, listData } = this.state;


        return (
            <div className="row">
                <div className="col-xl-4 card-col">
                    <div className="card">
                        <div className="card-header">
                            <h6>Top 10 {Lookup.display[forName] || forName}</h6>
                        </div>
                        <div className="card-body">
                            <div className="row list-group list-group-horizontal">
                                {listData.map((item, key) => (
                                    <a
                                        href={`/${forName}/${encodeURIComponent(
                                            item.name
                                        )}`}
                                        className="col-12 list-group-item border-0"
                                        key={item.name + key}
                                    >
                                        <div className="row" title={item.name}>
                                            <span className="col-9 col-sm-8 text-truncate">
                                                <small className="badge badge-secondary">
                                                    {key + 1}
                                                </small>{" "}
                                                {item.name}
                                            </span>
                                            <span className="col-3 col-sm-4 text-right">
                                                <label className="badge-primary badge badge-pill">
                                                    {utils.formatter(
                                                        this.props.sortBy,
                                                        item.count
                                                    )}
                                                </label>
                                            </span>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl-8">
                    <div className="card">
                        <div className="card-header">
                            <h6>{Lookup.display[forName] || forName}</h6>
                            <small className="text-muted">
                                {this.props.sortBy} Charts
                            </small>
                        </div>
                        <div className="card-body">
                            <ul className="nav nav-tabs" role="tablist">
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabWeekday)
                                    }}
                                >
                                    <a
                                        className="nav-link active"
                                        id="weekday-tab"
                                        data-toggle="tab"
                                        href="#weekday"
                                        role="tab"
                                        aria-controls="weekday"
                                        aria-selected="true"
                                    >
                                        {chartTabWeekday}
                                    </a>
                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabDaily)
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="daily-tab"
                                        data-toggle="tab"
                                        href="#daily"
                                        role="tab"
                                        aria-controls="daily"
                                        aria-selected="false"
                                    >
                                        {chartTabDaily}
                                    </a>

                                </li>
                                <li
                                    className="nav-item"
                                    onClick={() => {
                                        this.onChartSelected(chartTabHourly)
                                    }}
                                >
                                    <a
                                        className="nav-link"
                                        id="hourly-tab"
                                        data-toggle="tab"
                                        href="#hourly"
                                        role="tab"
                                        aria-controls="hourly"
                                        aria-selected="false"
                                    >
                                        {chartTabHourly}
                                    </a>
                                </li>
                                {this.state.chartTab !== chartTabDaily && (
                                    <li className="nav-item ml-auto">
                                        <div className="custom-select-container mr-5">
                                            <select
                                                className="custom-select custom-select-input"
                                                onChange={this.amPmHandler}
                                            >
                                                <option value="am/pm">
                                                    AM/PM
                                                </option>
                                                <option value="am">AM</option>
                                                <option value="pm">PM</option>
                                            </select>
                                        </div>
                                    </li>
                                )}
                            </ul>
                            <div className="tab-content">

                                <div
                                    className="tab-pane fade show active w-100"
                                    role="tabpanel"
                                    aria-labelledby="weekday-tab"
                                    id={chartTabWeekday}
                                    style={{ height: "400px" }}
                                >
                                    {this.renderHeatMap(this.state.weekdayChartData, this.state.chartKeys, "date")}
                                </div>

                                <div
                                    className="tab-pane fade"
                                    id={chartTabDaily}
                                    role="tabpanel"
                                    aria-labelledby="daily-tab"
                                >
                                    <div
                                        style={{ height: "300px" }}
                                        className="w-100"
                                    >
                                        {this.state.chartTab === chartTabDaily && this.renderLineChart(this.state.lineChartData)}
                                    </div>
                                </div>

                                <div
                                    className="tab-pane fade w-100"
                                    role="tabpanel"
                                    aria-labelledby="hourly-tab"
                                    id={chartTabHourly}
                                    style={{ height: "400px" }}
                                >
                                    {this.state.chartTab === chartTabHourly && this.renderHeatMap(this.state.heatChartData, this.state.chartKeys, "date")}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(OverviewTab);
