import React, { Component } from "react";
import * as utils from "../common/utils";
// Library

import { ApiList } from "../../config/apiList";
import Paths from "../../config/paths.json";

// importing logo images
import Logo from "../../images/logo.png";
import LogoIcon from "../../images/favicon.png";

import Search from "../search/index";
import UserImageBig from "../../images/user-image-upload.jpg";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import AvatarEditor from "react-avatar-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas);

const validEmailRegex = RegExp(
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
);
const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Nav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {},
      modules: [],
      activeNav: props.active,
      openPanel: false,
      firstName: "",
      email: "",
      lastName: "",
      avatar: UserImageBig,
      errors: {
        firstName: "",
        email: "",
        lastName: "",
      },
    };
  }

  componentWillMount() {
    document.documentElement.className = 'light'
    const profile = JSON.parse(localStorage.getItem("account_details") || "{}");

    let roles = profile.roles || [];

    if (roles.includes("admin")) {
      roles = ["dashboard"];
    }

    let modules = roles.map((item) => {
      return Paths[item];
    });

    if (modules.length === 0) {
      utils.logout();
      return;
    }
    // Array Merging for multiple module permission like dashbaord,dashboard_card and removing duplicate arrays of array
    //  refer paths.json file for user role permissions
    modules = Array.from(new Set(modules));

    modules = modules.filter(function (element) {
      return element !== undefined;
    });

    modules = [].concat.apply([], modules);

    this.setState({
      profile: profile,
      modules: modules,
    });
  }

  componentDidMount() {
    this.fetchUserData();
  }

  fetchUserData = async () => {
    const URL = `${ApiList.env}${ApiList.endpoint.profile}`;
    // fetch roles

    const response = await fetch(URL, {
      credentials: 'include'
    })

    if (response.status === 401) {
      window.location = "/login";
      return
    }
    const data = await response.json()

    this.setState({
      firstName: data.first_name,
      email: data.email,
      lastName: data.last_name,
      avatar: data.avatar,
      imageEditor: false,
      rotation: 0,
    });

    if (window.location.pathname === "/") {
      window.location.href = '/home'
    }
  };

  panelClose = () => {
    this.setState({
      openPanel: false,
    });
  };
  handleChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case "firstName":
        errors.firstName =
          value.length < 2 ? "First name must be 2 characters long!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Email is not valid!";
        break;
      case "lastName":
        errors.lastName =
          value.length < 2 ? "Last name mus be 2 characters long!" : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    if (
      validateForm(this.state.errors) &&
      this.state.firstName !== "" &&
      this.state.email !== "" &&
      this.state.lastName !== ""
    ) {

      const dataString = {
        email: this.state.email,
        last_name: this.state.lastName,
        first_name: this.state.firstName,
      };

      await fetch(`${ApiList.env}${ApiList.endpoint.profile}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataString),
      })

    } else {
      console.log("Error Form");
    }
  };

  readURL = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        avatar: reader.result,
      });
      //this.formHandler(file);
    };

    reader.readAsDataURL(file);
  };

  formHandler = async (file, src) => {
    console.log(file.size);
    const data = new FormData();
    data.append("image", file);

    const URL = `${ApiList.env}${ApiList.endpoint.uploadImage}`;

    await fetch(URL, {
      method: 'POST',
      body: data,
      credentials: 'include'
    })

    if (src) {
      this.setState({ avatar: src });
    }
  };

  changePassword = () => {
    window.location = "/changePassword";
  };

  onClickSave = () => {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        utils.fileResizer(canvas.toDataURL(), this.formHandler, 200, 200);
        this.setState({
          avatar: blob,
          imageEditor: false,
        });
      });
    }
  };

  setEditorRef = (editor) => (this.editor = editor);

  render() {
    const { errors, firstName, lastName, email, avatar } = this.state;

    return (
      <React.Fragment>
        <div
          className={this.state.openPanel ? "user-panel open" : "user-panel"}
        >
          <header>
            Profile{" "}
            <button
              onClick={() => {
                this.setState({ openPanel: false });
                return false;
              }}
              className="close-panel btn"
            >
              <FontAwesomeIcon icon="times" />
            </button>
          </header>

          <div className="profile-image-upload">
            {this.state.imageEditor ? (
              <div className="avatar-upload editor">
                <div className="avatar-edit">
                  <input
                    type="file"
                    id="imageUpload"
                    onChange={this.readURL}
                    accept=".png, .jpg, .jpeg"
                  />
                  <label htmlFor="imageUpload" />
                </div>
                <div className="avatar-preview">
                  <AvatarEditor
                    image={this.state.avatar}
                    width={200}
                    height={200}
                    border={0}
                    scale={1}
                    className="imagePreview"
                    rotate={this.state.rotation}
                    ref={this.setEditorRef}
                    onImageReady={(img) => {
                      console.log(img);
                    }}
                  />
                </div>
                <br />
                <div className="row">
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-sm text-white"
                      onClick={() => {
                        this.setState({
                          rotation: this.state.rotation - 90,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon="undo"
                        title="Rotate Left"
                      />
                    </button>
                  </div>
                  <div className="col-6 text-left">
                    <button
                      className="btn btn-sm text-white"
                      onClick={() => {
                        this.setState({
                          rotation: this.state.rotation + 90,
                        });
                      }}
                    >
                      <FontAwesomeIcon
                        icon="redo"
                        title="Rotate Right"
                      />
                    </button>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => {
                        this.onClickSave();
                      }}
                    >
                      <FontAwesomeIcon icon="check" /> Save
                    </button>
                  </div>
                </div>{" "}
              </div>
            ) : (
              <div className="avatar-upload">
                <div className="avatar-edit">
                  <button
                    className="edit-button"
                    onClick={() => {
                      this.setState({
                        imageEditor: true,
                      });
                    }}
                  >
                    <FontAwesomeIcon icon="user-edit" />
                  </button>
                </div>
                <div className="avatar-preview">
                  <div
                    id="imagePreview"
                    style={{
                      backgroundImage: "url(" + this.state.avatar + ")",
                    }}
                  />
                </div>
              </div>
            )}
          </div>
          <form onSubmit={this.handleSubmit} noValidate className="mt-3">
            <label>First Name</label>
            <input
              className="form-control"
              type="input"
              name="firstName"
              value={firstName}
              onChange={this.handleChange}
              noValidate
            />
            {errors.firstName.length > 0 && (
              <small className="error">{errors.firstName}</small>
            )}
            <label>Last Name</label>
            <input
              className="form-control"
              type="input"
              name="lastName"
              value={lastName}
              onChange={this.handleChange}
              noValidate
            />
            {errors.lastName.length > 0 && (
              <small className="error">{errors.lastName}</small>
            )}
            <label>Email</label>
            <input
              className="form-control"
              type="input"
              name="email"
              value={email}
              onChange={this.handleChange}
              noValidate
            />
            {errors.email.length > 0 && (
              <small className="error">{errors.email}</small>
            )}
            <div className="mt-5 pt-3 text-center">
              <button
                className="btn btn-primary btn-lg col"
                type="submit"
                onClick={() => {
                  this.setState({ openPanel: false });
                }}
              >
                Update
              </button>
            </div>
          </form>
        </div>
        {this.state.openPanel ? (
          <div className="bg-overlay" onClick={this.panelClose} />
        ) : null}
        <header>
          <nav className="navbar navbar-expand-lg fixed-top">
            <button
              className="btn sidebar-toggle"
              onClick={() => {
                document.body.classList.toggle("sidebar-expanded");
              }}
            >
              <FontAwesomeIcon
                icon="chevron-right"
                title="Toggle Sidebar"
              />
            </button>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon text-primary">
                <FontAwesomeIcon
                  icon="bars"
                  title="Toggle Menu"
                />
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li>
                  <Search />
                </li>
              </ul>
              <div className="profile-image">
                <div>
                  <div className="btn-group dropdown">
                    <button
                      type="button"
                      className="btn dropdown-toggle text-truncate text-capitalize border-0"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <img src={avatar} alt={"avatar"} />
                    </button>
                    <div className="dropdown-menu dropdown-menu-right mt-2">
                      <button
                        className="dropdown-item text-capitalize"
                        type="button"
                        onClick={() => {
                          this.setState({
                            openPanel: true,
                          });
                        }}
                      >
                        Profile
                      </button>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={this.changePassword}
                      >
                        Change Password
                      </button>
                      <button
                        className="dropdown-item"
                        type="button"
                        onClick={utils.logout}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          <div className="header-placeholder" />
        </header>

        <section className="left-navigation">
          <a className="btn navbar-brand" href="/home">
            <img className="icon" src={LogoIcon} alt="SyncMedia" />
            {/* <img className="img-fluid logo dark-logo" src={Logo} alt="SyncMedia" />
            <img className="img-fluid logo light-logo" src={LogoDark} alt="SyncMedia" /> */}
            <img
              className="img-fluid logo color-logo"
              src={Logo}
              alt="SyncMedia"
            />
          </a>
          <ul className="list-unstyled">
            {this.state.modules.map((i) => {
              return (
                <li key={i.name} className={i.className}>
                  <a
                    className={
                      this.state.activeNav.indexOf(i.path) > -1 ? "active" : ""
                    }
                    href={i.path}
                    title={i.name}
                    target={i.target}
                  >
                    {i.svg ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: i.svg,
                        }}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={i.icon}
                        title={i.name}
                      />
                    )}
                    <span className="label">{i.name}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </section>
      </React.Fragment>
    );
  }
}
export default Nav;
