import React, { Component } from "react";

import { ApiList } from "../../config/apiList";

import VerticalBar from "../charts/VerticalRoundedBar";

import API from "../../config/api";
import * as utils from "../common/utils";

class TopCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topCardsFlag: false,
            topCardData: {
                curr: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
                prev: {
                    playout: 0,
                    airtime: 0,
                    spend: 0,
                },
            },
            _dateRange: ""
        };
    }

    componentDidMount() {
        this.setState({
            _dateRange: utils.subscribeDateRange(this.topCardInfo)
        })

        this.topCardInfo();
    }

    componentWillUnmount() {
        utils.unsubscribeDateRange(this.state._dateRange)
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.topCardInfo();
        }
    }

    topCardInfo = async () => {
        const URL = ApiList.env + ApiList.endpoint.summary;
        const data = await API.call(URL) // done
        this.setState({
            topCardData: data,
            topCardsFlag: true,
        });
    };

    render() {
        const { topCardData, topCardsFlag } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-xl-4">
                        {topCardsFlag ? (
                            <VerticalBar
                                id={"topDashboardFirst"}
                                title={"playout"}
                                prevCount={topCardData.prev.playout}
                                currCount={topCardData.curr.playout}
                                color={{
                                    start: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue("--color-10"),
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="col-xl-4">
                        {topCardsFlag ? (
                            <VerticalBar
                                id={"topDashboardSecond"}
                                title={"airtime"}
                                prevCount={topCardData.prev.airtime}
                                currCount={topCardData.curr.airtime}
                                color={{
                                    start: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue("--color-9"),
                                }}
                            />
                        ) : null}
                    </div>
                    <div className="col-xl-4">
                        {topCardsFlag ? (
                            <VerticalBar
                                id={"topDashboardThird"}
                                title={"spend"}
                                prevCount={topCardData.prev.spend}
                                currCount={topCardData.curr.spend}
                                color={{
                                    start: getComputedStyle(
                                        document.documentElement
                                    ).getPropertyValue("--color-3"),
                                }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default TopCards;
