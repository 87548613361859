import React, { Component } from "react";

import { Line } from "react-chartjs-2";
import * as utils from "../common/utils";
class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      labels: props.labels,
      data: props.data,
      colors: [
        "rgb(91, 202, 221)",
        "rgb(166, 224, 206)",
        "rgb(255, 152, 120)",
        "rgb(58, 180, 73)",
        "rgb(234, 86, 124)",
      ],
      preGradient: [
        "rgba(91, 202, 221,0.2)",
        "rgba(166, 224, 206,0.2)",
        "rgba(255, 152, 120,0.2)",
        "rgba(58, 180, 73,0.2)",
        "rgba(234, 86, 124,0.2)",
      ]
    };
  }

  render() {
    const dataLine = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 450);
      gradient.addColorStop(0, "rgba(239,160,124, 0.2)");
      gradient.addColorStop(1, "rgba(0,0,0,0)");
      let datasets = this.props.multi
        ? this.state.data.map((i, index) => {
            return {
              label: i.name,
              fill: true,
              //spanGaps: true,
              data: i,
              borderColor: this.state.colors[index],
              //pointBackgroundColor: "#fff",
              pointBorderColor: this.state.colors[index],
              pointHoverBackgroundColor: this.state.colors[index],
              pointHoverBorderColor: "rgba(134, 54, 126,0.5)",
              pointRadius: 4,
              pointBorderWidth: 2,
              pointHoverRadius: 5,
              labelTextColor: "red",
              //background: "rgba(134, 54, 126,0.5)",
              backgroundColor: this.state.preGradient[index],
            };
          })
        : [
            {
              label: this.state.labels,
              data: this.state.data,
              borderColor: this.state.colors[0],
              pointBackgroundColor: "#fff",
              pointBorderColor: this.state.colors[0],
              pointHoverBackgroundColor: this.state.colors[0],
              pointHoverBorderColor: "rgba(134, 54, 126,0.5)",
              pointRadius: 4,
              pointBorderWidth: 2,
              pointHoverRadius: 5,
              fill: true,
              background: "rgba(134, 54, 126,0.5)",
            },
          ];
      return {
        labels: this.state.labels,
        datasets: datasets,
      };
    };
    var lineOption = {
      responsive: true,
      maintainAspectRatio: false,
      tooltips: {
        mode: "index",
        intersect: true,
        callbacks: {
          label: (tooltipItems, data) => {
            return utils.formatter(this.props.sortBy, tooltipItems.yLabel);
          },
        },
      },
      animation: {
        easing: "easeInOutQuad",
        duration: 520,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              color: "#464967",
              display: false,
              lineWidth: 1,
              zeroLineColor: "#464967",
            },
            ticks: { padding: 10 },
          },
        ],
        yAxes: [
          {
            gridLines: {
              color: "#464967",
              display: false,
              lineWidth: 1,
              zeroLineColor: "#464967",
            },
            ticks: {
              beginAtZero: false,
              padding: 3,
              callback: (value, index, values) => {
                return utils.formatter(this.props.sortBy, value);
              },
            },
          },
        ],
      },
      elements: {
        line: {
          tension: 0.4,
        },
        point: { radius: 0 },
      },
      legend: {
        display: this.props.multi ? true : false,
        backgroundColor: getComputedStyle(
            document.documentElement
          ).getPropertyValue("--text-color"),
        labels: {
          usePointStyle: true,
          
        },
      },
    };

    return (
      <div className="h-100">
        <Line data={dataLine} options={lineOption} />
      </div>
    );
  }
}

export default LineChart;
