
export const ApiList = {
  env: import.meta.env.VITE_APP_STAGE === "debug" ? "http://pankaj-api.syncmedia.io:9000/v1.0/" : "https://prod-api.syncmedia.io/v1.0/",
  endpoint: {
    login: "auth/login",
    profile: "account/profile",
    statistics: "dashboard/summary/statistics/",
    summary: "dashboard/summary/info",
    list: "dashboard/summary/collations/channels/top",
    topList: "dashboard/summary/collations",
    getIcon: "icons/",
    collection_info: "dashboard/summary/collations/",
    search: "dashboard/search/pivot",
    similar: "dashboard/similar/",
    uploadImage: "account/avatar",
    changepassword: "account/password",
    resetpassword: "accounts/password_reset/request/",
    updatepassword: "accounts/password_reset",
    logout: "auth/logout",
    info: "dashboard/info"
  }
}