import moment from "moment";
import * as utils from "../components/common/utils";
import toast from 'react-hot-toast'

type Result = (item: any) => void

const maxRunners = 3

let currentRunners = 0;
const tasks = new Map<string, Result[]>()

let isShowingLoading = false

const API = {
  call: (endpoint: string, skip: boolean = false) => {
    return new Promise((resolve, reject) => {

      const countryFilter = `country=${utils.getCountry()}`

      if (endpoint.includes("?")) {
        endpoint = endpoint + `&${countryFilter}`
      } else {
        endpoint = endpoint + `?${countryFilter}`
      }

      if (!skip) {
        const filter = utils.apiPageFilters()
        if ('key' in filter) {
          endpoint = endpoint + `&${filter.key}=${encodeURIComponent(filter.value)}`
        }
      }

      if (endpoint.includes("since=")) {
        debugger
      }

      const range = utils.dateRange()

      endpoint = endpoint +
        `&since=${encodeURIComponent(range.since.startOf('day').format("YYYY-MM-DD HH:mm:ss"))
        }&till=${encodeURIComponent(range.till.endOf('day').format("YYYY-MM-DD HH:mm:ss"))
        }`

      console.log("new req", endpoint);

      queueTask(endpoint, resolve)
    });
  }
};

const queueTask = (
  endpoint: string,
  resolve: Result
) => {

  if (tasks.has(endpoint)) {
    const promises = tasks.get(endpoint)!
    promises.push(resolve)
    tasks.set(endpoint, promises)

    return
  }

  tasks.set(endpoint, [resolve])

  if (currentRunners == maxRunners) {
    return
  }

  loadData(endpoint)
}

const runNextTask = async () => {
  const endpoints = Array.from(tasks.keys())
  if (endpoints.length === 0) {
    console.log("API: no further tasks");

    if (isShowingLoading) {
      isShowingLoading = false
      // console.log('loading.... off')
      toast.dismiss()
    }

    return
  }

  if (!isShowingLoading) {
    isShowingLoading = true
    // console.log('loading....')
    toast.loading("Please wait...", {
      position: 'top-center'
    })
  }

  loadData(endpoints[0])
}

const loadData = async (endpoint: string) => {
  currentRunners = currentRunners + 1

  const data = await loadDataImpl(endpoint)

  currentRunners = currentRunners - 1

  const promises = tasks.get(endpoint) || []
  tasks.delete(endpoint)

  promises.forEach(x => x?.(data))

  runNextTask()
}

const loadDataImpl = async (endpoint: string) => {


  const response = await fetch(endpoint, {
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    method: "GET",
  })

  const data = await response.json()

  return data
}

export default API;
