import React, { Suspense } from "react";
import ReactDOM from "react-dom";
//import { Route, BrowserRouter, Switch, Redirect } from "react-router-dom"; **
import { Route, BrowserRouter, Switch } from "react-router-dom";

import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "./style/LineIcons/WebFont/font-css/LineIcons.css";
// Custom css
import "./style/style.css";

// const components
import ErrorBoundary from "./components/common/errorBoundary"

import Layout from "./components/common/layout"
import AuthSignIN from "./components/auth/sign_in"
import ForgotPassword from "./components/auth/forgot_password"

import ResetPassword from "./components/auth/reset_password"

import Dashboard from "./components/dashboard/index";
import Channels from "./components/channels/index"

import ChannelIndividual from "./components/channels/individual"

import Categories from "./components/categories/index"
import CategoriesIndividual from "./components/categories/individual"

import Brands from "./components/brands/index"
import BrandsIndividual from "./components/brands/individual"
import Products from "./components/products/index"
import ProductsIndividual from "./components/products/individual"

import ChangePassword from "./components/changepassword/index"

import NoMatch from "./components/common/no_match"

import { Toaster } from 'react-hot-toast';

const Router = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ErrorBoundary >
        <Toaster />
        <BrowserRouter>
          <Switch>
            <Route exact path="/login" component={AuthSignIN} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route exact path="/public/:action" component={ResetPassword} />
            <Route exact path="/changepassword" component={ChangePassword} />
            <Layout>
              <Route
                component={({ match }) => (
                  <div>
                    <Route exact path="/home" component={Dashboard} />
                    <Route exact path="/channels" component={Channels} />
                    <Route
                      exact
                      path="/channels/:channel_name"
                      component={ChannelIndividual}
                    />
                    <Route exact path="/categories" component={Categories} />
                    <Route
                      exact
                      path="/categories/:category_name"
                      component={CategoriesIndividual}
                    />
                    <Route exact path="/brands" component={Brands} />
                    <Route
                      exact
                      path="/brands/:brand_name"
                      component={BrandsIndividual}
                    />
                    <Route exact path="/products" component={Products} />
                    <Route
                      exact
                      path="/products/:product_name"
                      component={ProductsIndividual}
                    />
                  </div>
                )}
              />
            </Layout>
            <Route component={NoMatch} />
          </Switch>

        </BrowserRouter>
      </ErrorBoundary>
    </Suspense>
  )
}

ReactDOM.render(
  Router(),
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
